import { TCard } from "models/CardModel";
import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

export type TSearchValues = {
  [key: string]: any;
};

export type TSearchLabels = {
  [key: string]: any;
};

const searchValuesState = atomFamily<TSearchValues, TCard["uuid"]>({
  key: "searchValuesState",
  default: {},
});

const searchValuesIdsState = atom<TCard["uuid"][]>({
  key: "SearchValues/Ids",
  default: [],
});

const searchLabelsState = atomFamily<TSearchLabels, TCard["uuid"]>({
  key: "searchLabelsState",
  default: {},
});

const searchValuesSelector = selectorFamily({
  key: "SearchValues/Selector",
  get:
    (id) =>
    ({ get }) => {
      const atom = get(searchValuesState(id as TCard["uuid"]));
      return atom;
    },
  set:
    (id) =>
    ({ get, set }, newValues) => {
      const newId = id as TCard["uuid"];
      set(searchValuesState(newId), newValues);
      const ids = get(searchValuesIdsState);
      if (!ids.includes(newId)) {
        const newIds = [...ids];
        newIds.push(newId);
        set(searchValuesIdsState, newIds);
      }
    },
});

const allSearchValuesSelector = selector<({ cardId: TCard["uuid"]; values: TSearchValues } | undefined)[]>({
  key: "SearchValues/AllSelector",
  get: ({ get }) => {
    const ids = get(searchValuesIdsState);
    return ids.map((id) => {
      return {
        cardId: id,
        values: get(searchValuesState(id)),
      };
    });
  },
});

export const useSearchValuesState = (card: TCard) => {
  const searchValues = useRecoilValue(searchValuesSelector(card.uuid));
  const setSearchValues = useSetRecoilState(searchValuesSelector(card.uuid));

  const searchLabels = useRecoilValue(searchLabelsState(card.uuid));
  const setSearchLabels = useSetRecoilState(searchLabelsState(card.uuid));

  return { searchValues, setSearchValues, searchLabels, setSearchLabels };
};

// [ToDo] ↑↓ こいつら整理

export const useSearchValuesState2 = () => {
  const getSearchValues = useRecoilCallback(({ snapshot }) => async (card: TCard) => {
    return await snapshot.getPromise(searchValuesState(card.uuid));
  });

  const setSearchValues = useRecoilCallback(({ set, snapshot }) => async (card: TCard, values: TSearchValues) => {
    set(searchValuesState(card.uuid), values);
    const ids = await snapshot.getPromise(searchValuesIdsState);
    if (!ids.includes(card.uuid)) {
      const newIds = [...ids];
      newIds.push(card.uuid);
      set(searchValuesIdsState, newIds);
    }
  });

  const getAllSearchValues = useRecoilCallback(({ snapshot }) => async () => {
    const values = await snapshot.getPromise(allSearchValuesSelector);
    return values;
  });

  return {
    getSearchValues,
    setSearchValues,
    getAllSearchValues,
  };
};
