export const drawerWidth = 260;
export const editModeDrawerWidth = 400;

type TConfigs = {
  api: {
    schema: string;
    host: string;
    directory: string;
    baseUrl: string;
  };
};

export const getCockpitConfigs = () => {
  const configs: TConfigs = (window as any).CockpitConfigs;
  const baseUrl = `${configs.api.schema}://${configs.api.host}/${configs.api.directory}/`;
  configs.api.baseUrl = baseUrl;
  return { configs, baseUrl };
};
